import React from 'react';

const ConnectAmazon = () => {
  const handleConnect = () => {
    window.location.href = 'https://sellercentral.amazon.com/apps/authorize/...'; // OAuth URL
  };

  return (
    <div>
      <h2>Connect to Amazon Seller Account</h2>
      <button onClick={handleConnect}>Connect Now</button>
    </div>
  );
};

export default ConnectAmazon;
